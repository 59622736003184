import { EIcon, Icon } from '@arcanna/generic';
import { useJobIdFromUrl } from '@arcanna/hooks';
import { IconButton, Stack, Typography, useTheme } from '@mui/material';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { config } from 'src/config';
import { EActionCenter, EActionCenterSubTypes } from '../../ActionCenter.types';
import { actionCardIconMap, getActionCardText, actionCardTitleMap } from './ActionCard.utils';
import { TFilterItem } from '@arcanna/components';
import { ARCANNA_KNOWLEDGE_BASE_STATE } from '../../../../../../../components/pages/JobEventExplorer/models/constants';
import { EFilterOperatorMultiple } from '../../../../Filters/AdvancedFilters/AdvancedFilters.types';
import { useUrlFilters } from '../../../../../../../components/shared/hooks/useUrlFilters';

type TActionCardProps = {
  type: EActionCenter;
  subtype: EActionCenterSubTypes;
  value: number;
};

enum FieldIdsToTakeActionOn {
  OUTLIER = 'arcanna.outlier_flag',
  CONSENSUS = 'arcanna.consensus'
}

function ActionCard({ type, subtype, value }: TActionCardProps) {
  const { palette } = useTheme();
  const history = useHistory();
  const { jobId } = useJobIdFromUrl();
  const { filtersToParams, encode } = useUrlFilters();

  const redirectUrl = useMemo(() => {
    switch (subtype) {
      case EActionCenterSubTypes.OUTLIERS:
        return (
          config.routes.eventExplorer.replace(':id', String(jobId)) +
          '?filters=' +
          encode(
            filtersToParams({
              job_id: jobId,
              advancedFilters: [{ field: FieldIdsToTakeActionOn.OUTLIER, operator: 'is', value: 'true' } as TFilterItem]
            })
          )
        );
      case EActionCenterSubTypes.LOW_CONFIDENCE_SCORE:
        return config.routes.eventExplorer.replace(':id', String(jobId));
      case EActionCenterSubTypes.UNDECIDED_CONSENSUS:
        return (
          config.routes.eventExplorer.replace(':id', String(jobId)) +
          '?filters=' +
          encode(
            filtersToParams({
              job_id: jobId,
              advancedFilters: [{ field: FieldIdsToTakeActionOn.CONSENSUS, operator: 'is', value: 'undecided' } as TFilterItem]
            })
          )
        );
      case EActionCenterSubTypes.NEEDS_TRAINING:
        return (
          config.routes.eventExplorer.replace(':id', String(jobId)) +
          '?filters=' +
          encode(
            filtersToParams({
              job_id: jobId,
              advancedFilters: [
                {
                  field: ARCANNA_KNOWLEDGE_BASE_STATE,
                  operator: EFilterOperatorMultiple.isOneOf,
                  value: ['retrain'],
                  valueLabel: 'Retrain',
                  label: 'Training status',
                  status: 'active',
                  id: ARCANNA_KNOWLEDGE_BASE_STATE
                } as TFilterItem
              ]
            })
          )
        );
      default:
        return config.routes.eventExplorer.replace(':id', String(jobId));
    }
  }, [jobId, subtype, encode, filtersToParams]);

  return (
    <Stack
      minWidth="189px"
      maxWidth="189px"
      borderRadius="12px"
      border="1px solid"
      borderColor={palette.secondary[800]}
      onClick={() => {
        history.push(redirectUrl);
      }}
      sx={{ cursor: 'pointer' }}
    >
      <Stack
        gap={1}
        direction="row"
        justifyContent="flex-start"
        alignItems="center"
        p="4px 12px"
        bgcolor={palette.secondary[800]}
        borderRadius="12px 12px 0 0"
      >
        <Icon name={actionCardIconMap[type]} fontSize="small" htmlColor={palette.secondary[300]} />
        <Typography variant="subtitle2" color={palette.secondary[100]} fontWeight={500}>
          {actionCardTitleMap[type]}
        </Typography>
      </Stack>
      <Stack p="12px 12px 16px 12px" height="68px" bgcolor={palette.secondary[1000]}>
        {getActionCardText(palette, subtype, value)}
      </Stack>
      <Stack
        p="0px 12px 8px 12px"
        borderRadius="0 0 12px 12px"
        bgcolor={palette.secondary[1000]}
        justifyContent="flex-end"
        direction="row"
      >
        <IconButton sx={{ p: 0 }}>
          <Icon name={EIcon.ChevronRight} fontSize="small" htmlColor={palette.secondary[300]} />
        </IconButton>
      </Stack>
    </Stack>
  );
}

export default ActionCard;
